import React from 'react'
import { getCsrfToken, getSession } from 'next-auth/react'

import LoginForm from '@/modules/Login/components/LoginForm'
import HeadSeo from '@/modules/Events/components/ui/HeadSeo'
import { defaultRedirect } from '@/config/app'

export async function getServerSideProps(context: any) {
	const session = await getSession(context)

	// Define a default callback route if it's not present in the url
	// to force a redirect
	const { callbackUrl = defaultRedirect } = context.query

	if (session !== null) {
		return {
			redirect: {
				destination: defaultRedirect,
				permanent: false,
			},
		}
	}

	const csrfToken = await getCsrfToken(context)

	return {
		props: {
			csrfToken: csrfToken || null,
			callbackUrl: callbackUrl,
		},
	}
}

function Login({ csrfToken, callbackUrl }: { csrfToken: string; callbackUrl: string }) {
	return (
		<>
			<HeadSeo title="Login" />
			<LoginForm csrfToken={csrfToken} callbackUrl={callbackUrl} />
			<span className="fixed bottom-5 right-10 text-xs text-gray-500">
				v{process.env.NEXT_PUBLIC_APP_VERSION}
			</span>
		</>
	)
}

export default Login
