import PropTypes from 'prop-types'
import Head from 'next/head'

type HeaderSeoProps = {
	title: string
}

const HeadSeo = (props: HeaderSeoProps) => {
	const { title } = props
	return (
		<Head>
			<title>{title}</title>
			<meta charSet="utf-8" />
			<meta httpEquiv="X-UA-Compatible" content="IE=edge" />
			<meta
				name="viewport"
				content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
			/>
		</Head>
	)
}

HeadSeo.propTypes = {
	title: PropTypes.string,
}

export default HeadSeo
