import { useState } from 'react'
import { Alert, Button, Input } from 'antd'
import * as Yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useRouter } from 'next/router'
import { signIn } from 'next-auth/react'

const schema = Yup.object({
	email: Yup.string()
		.max(50, 'Debe tener 30 caracteres o menos')
		.email('Por favor ingresa un correo electrónico válido')
		.required('Por favor ingresa tu correo electrónico'),
	password: Yup.string()
		.min(5, 'Mínimo 5 caracteres')
		.max(20, 'Máximo 20 caracteres')
		.required('Por favor ingresa tu contraseña'),
})

export type Props = {
	csrfToken: string
	callbackUrl: string
}

function LoginForm({ csrfToken, callbackUrl }: Props) {
	const router = useRouter()
	const [status, setStatus] = useState('idle')
	const [message, setMessage] = useState(null)

	const methods = useForm({
		mode: 'onChange',
		resolver: yupResolver(schema),
		defaultValues: {
			email: '',
			password: '',
		},
	})

	const {
		handleSubmit,
		control,
		formState: { errors, isValid },
	} = methods

	const onSubmit = async (values: { email: string; password: string }) => {
		setStatus('loading')
		try {
			const response: any = await signIn('credentials', {
				redirect: false,
				email: values.email,
				password: values.password,
				callbackUrl: callbackUrl,
			})

			if (response.error) {
				throw new Error(response.error)
			}

			if (response.url) {
				await router.push(response.url)
			}
			setStatus('success')
		} catch (error: any) {
			setMessage(error.message)
			setStatus('error')
		}
	}

	return (
		<main className="flex flex-col min-h-screen h-max bg-gray-50">
			<div className="container max-w-[380px] px-6">
				<header className="my-8">
					<div className="logo w-56 mx-auto py-4">
						<img src="/vaissen.svg" alt="vaissen" />
					</div>
				</header>
				<div className="px-8 py-10 mx-auto bg-white border drop-shadow-lg rounded">
					<header>
						<div className="login-title mx-auto text-2xl text-center text-slate-900 py-4">
							Iniciar Sesión
						</div>
						<div>{message && <Alert type="error" message={message} closable />}</div>
					</header>
					<form className="form-confirm py-4" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
						<div className="">
							<input name="csrfToken" type="hidden" defaultValue={csrfToken} />
							<div className="mb-4">
								<label htmlFor="email" className="text-sm text-gray-600 font-semibold">
									Correo electrónico
									<Controller
										name="email"
										control={control}
										render={({ field: { value, onChange } }) => (
											<Input
												status={errors?.email ? 'error' : ''}
												placeholder="Ingresa tu correo electrónico"
												value={value}
												size="large"
												autoComplete="new-email"
												onChange={onChange}
											/>
										)}
									/>
								</label>
								<span className="text-red-400 text-xs">
									{errors?.email ? errors.email.message : null}
								</span>
							</div>

							<div className="mb-8">
								<label htmlFor="email" className="text-sm text-gray-600 font-semibold">
									Contraseña
									<Controller
										name="password"
										control={control}
										render={({ field: { value, onChange } }) => (
											<Input.Password
												status={errors?.password ? 'error' : ''}
												placeholder="Ingresa tu password"
												value={value}
												min={5}
												max={10}
												autoComplete="new-password"
												onChange={onChange}
											/>
										)}
									/>
								</label>
								<span className="text-red-400 text-xs">
									{errors?.password ? errors.password.message : null}
								</span>
							</div>

							<div className="flex items-center justify-center gap-4 flex-col">
								<Button
									className="btn-login w-full drop-shadow-lg font-bold rounded-md"
									size="large"
									htmlType="submit"
									type="primary"
									disabled={!isValid || status === 'loading'}
									loading={status === 'loading'}
								>
									<span>Iniciar sesión</span>
								</Button>

								<Button type="link">Olvidé mi contraseña</Button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</main>
	)
}

export default LoginForm
